export const options = [
  { key: "WFH", value: "WFH" },
  { key: "WFO", value: "WFO" },
  { key: "WFA", value: "WFA" },
  { key: "Workshop", value: "Workshop" },
  { key: "Leave (Cuti)", value: "Leave (Cuti)" },
  { key: "Leave (Sakit)", value: "Leave (Sakit)" },
];

export const DEFAULT_BORDER_STYLE = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
};

export const DEFAULT_ALIGNMENT = {
  vertical: "center",
  horizontal: "center",
  wrapText: true,
};

export const CENTER_CONTENT_WRAP_STYLE = {
  alignment: DEFAULT_ALIGNMENT,
  font: { sz: 14 },
};

export const WRAP_CONTENT_STYLE = {
  alignment: { wrapText: true, vertical: "center" },
  font: { sz: 14 },
};

export const DEFAULT_HEADER_STYLE = {
  alignment: DEFAULT_ALIGNMENT,
  font: { color: { rgb: "FFFFFF" }, sz: 14 },
  fill: { fgColor: { rgb: "539165" } },
};

export const DEFAULT_FOOTER_STYLE = {
  ...CENTER_CONTENT_WRAP_STYLE,
  fill: { fgColor: { rgb: "5D9C59" }, sz: 14 },
  font: { bold: true },
};

export const SUMMARY_STYLE = {
  ...CENTER_CONTENT_WRAP_STYLE,
  font: { bold: true },
};

export const holidaysMap = new Map([
  [
    new Date("2023-04-07").toLocaleDateString("id"),
    {
      date: new Date("2023-4-07").toLocaleDateString("ID"),
      value: "Jumat Agung",
    },
  ],
  [
    new Date("2023-04-21").toLocaleDateString("id"),
    {
      date: new Date("2023-04-21").toLocaleDateString("ID"),
      value: "Cuti bersama Lebaran",
    },
  ],
  [
    new Date("2023-04-22").toLocaleDateString("id"),
    {
      date: new Date("2023-04-22").toLocaleDateString("ID"),
      value: "Hari Raya Idul Fitri",
    },
  ],
  [
    new Date("2023-04-23").toLocaleDateString("id"),
    {
      date: new Date("2023-04-23").toLocaleDateString("ID"),
      value: "Cuti bersama Lebaran",
    },
  ],
  [
    new Date("2023-04-24").toLocaleDateString("id"),
    {
      date: new Date("2023-04-24").toLocaleDateString("ID"),
      value: "Cuti bersama Lebaran",
    },
  ],
  [
    new Date("2023-04-25").toLocaleDateString("id"),
    {
      date: new Date("2023-04-25").toLocaleDateString("ID"),
      value: "Cuti bersama Lebaran",
    },
  ],
  [
    new Date("2023-04-26").toLocaleDateString("id"),
    {
      date: new Date("2023-04-26").toLocaleDateString("ID"),
      value: "Cuti bersama Lebaran",
    },
  ],
  [
    new Date("2023-05-01").toLocaleDateString("id"),
    {
      date: new Date("2023-05-01").toLocaleDateString("ID"),
      value: "Hari Buruh",
    },
  ],
  [
    new Date("2023-05-18").toLocaleDateString("id"),
    {
      date: new Date("2023-05-18").toLocaleDateString("ID"),
      value: "Kenaikan Isa Almasih",
    },
  ],
  [
    new Date("2023-06-01").toLocaleDateString("id"),
    {
      date: new Date("2023-06-01").toLocaleDateString("ID"),
      value: "Hari Lahirnya Pancasila",
    },
  ],
  [
    new Date("2023-06-02").toLocaleDateString("id"),
    {
      date: new Date("2023-06-02").toLocaleDateString("ID"),
      value: "Cuti Bersama Waisak",
    },
  ],
]);

export const weekday = [
  "Minggu",
  "Senin",
  "Selasa",
  "Rabu",
  "Kamis",
  "Jumat",
  "Sabtu",
];

export const PROTECTED_ROUTES = ["/profile", "/managements", "/reports"];

export const NON_ADMIN_ROUTES = ["/profile", "/documents"];
export const THEME_STORAGE = localStorage.getItem("THEME_STORAGE");
export const SUCCESS_STATUS = [200, 201];

export const CHIP_DATA = {
  approved: {
    styles: {
      color: "success",
    },
    text: "Approved",
  },
  process: {
    styles: {
      color: "warning",
    },
    text: "On Process",
  },
  rejected: {
    styles: {
      color: "error",
    },
    text: "Rejected",
  },
};

export const MAP_CHIP_DATA = new Map(Object.entries(CHIP_DATA));

export const MUTATE_METHODS = ["POST", "PUT", "PATCH", "DELETE"];
export const NO_AUTH_ENDPOINTS = [
  "/auth/login",
  "/auth/register",
  "/auth/send-reset-link",
  "/auth/refresh-token",
  "/auth/redirect",
];
