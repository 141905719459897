import { auth, db } from "config/firebase";
import {
  doc,
  setDoc,
  query,
  collection,
  getDocs,
  where,
  updateDoc,
  serverTimestamp,
  deleteDoc,
  Timestamp,
  orderBy,
} from "firebase/firestore";
import { addNewUser } from "./request-template";
import jwtDecode from "jwt-decode";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { getUUID } from "utils";

export const addTool = async (payload) => {
  const timeNow = serverTimestamp();
  try {
    await setDoc(doc(db, "tools", payload.id), {
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addHoliday = async (payload) => {
  const timeNow = serverTimestamp();
  try {
    await setDoc(doc(db, "holidays", payload.id), {
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addRequest = async (payload) => {
  const timeNow = serverTimestamp();
  try {
    await setDoc(doc(db, "requests", payload.id), {
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addUser = async (payload) => {
  const timeNow = serverTimestamp();
  try {
    await setDoc(doc(db, "users", payload.id), {
      ...addNewUser,
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addProgress = async (payload) => {
  const timeNow = serverTimestamp();
  try {
    await setDoc(doc(db, "lov-progress", payload.id), {
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addPresence = async (payload) => {
  const timeNow = serverTimestamp();
  const id = getUUID();

  try {
    await setDoc(doc(db, "presences", id), {
      id,
      created_at: timeNow,
      updated_at: timeNow,
      ...payload,
      clock_out: payload?.clock_out
        ? payload?.clock_out
        : payload.work_mode.includes("Leave")
        ? timeNow
        : "",
      clock_in: payload?.clock_in ? payload?.clock_in : timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addLogbook = async (payload) => {
  const timeNow = serverTimestamp();

  try {
    await setDoc(doc(db, "logbooks", payload.id), {
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addProject = async (payload) => {
  const timeNow = serverTimestamp();
  try {
    await setDoc(doc(db, "projects", payload.id), {
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addRole = async (payload) => {
  const timeNow = serverTimestamp();
  try {
    await setDoc(doc(db, "roles", payload.id), {
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addWorkplace = async (payload) => {
  const timeNow = serverTimestamp();
  try {
    await setDoc(doc(db, "workplaces", payload.id), {
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const addTeam = async (payload) => {
  const timeNow = serverTimestamp();
  try {
    await setDoc(doc(db, "teams", payload.id), {
      ...payload,
      created_at: timeNow,
      updated_at: timeNow,
    });
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateWorkplace = async (payload) => {
  try {
    await updateDoc(
      doc(db, "workplaces", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateRequest = async (payload) => {
  try {
    await updateDoc(
      doc(db, "requests", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateTool = async (payload) => {
  try {
    await updateDoc(
      doc(db, "tools", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateTeam = async (payload) => {
  try {
    await updateDoc(
      doc(db, "teams", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updatePresence = async (payload) => {
  try {
    await updateDoc(
      doc(db, "presences", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateUser = async (payload) => {
  try {
    await updateDoc(
      doc(db, "users", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateRole = async (payload) => {
  try {
    await updateDoc(
      doc(db, "roles", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateHoliday = async (payload) => {
  try {
    await updateDoc(
      doc(db, "holidays", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateLogbook = async (payload) => {
  try {
    await updateDoc(
      doc(db, "logbooks", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateProject = async (payload) => {
  try {
    await updateDoc(
      doc(db, "projects", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const updateProgress = async (payload) => {
  try {
    await updateDoc(
      doc(db, "lov-progress", payload.id),
      {
        ...payload,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const verifyUserAccount = async (payload) => {
  try {
    await updateDoc(
      doc(db, "users", payload.id),
      {
        ...payload,
        is_verified: !payload.is_verified,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteUser = async (userId) => {
  try {
    await updateDoc(
      doc(db, "users", userId),
      {
        is_active: false,
        updated_at: serverTimestamp(),
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteRole = async (payload) => {
  try {
    await deleteDoc(doc(db, "roles", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteHoliday = async (payload) => {
  try {
    await deleteDoc(doc(db, "holidays", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteTool = async (payload) => {
  try {
    await deleteDoc(doc(db, "tools", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteTeam = async (payload) => {
  try {
    await deleteDoc(doc(db, "teams", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteWorkplace = async (payload) => {
  try {
    await deleteDoc(doc(db, "workplaces", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deletePresence = async (payload) => {
  try {
    await deleteDoc(doc(db, "presences", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteLogbook = async (payload) => {
  try {
    await deleteDoc(doc(db, "logbooks", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteProject = async (payload) => {
  try {
    await deleteDoc(doc(db, "projects", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteProgress = async (payload) => {
  try {
    await deleteDoc(doc(db, "lov-progress", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const deleteRequest = async (payload) => {
  try {
    await deleteDoc(doc(db, "requests", payload.id));
    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const getAllPresenceData = async (queryParams) => {
  try {
    const q =
      !queryParams?.startDate && !queryParams?.endDate
        ? query(collection(db, "presences"), orderBy("clock_in", "desc"))
        : query(
            collection(db, "presences"),
            queryParams?.startDate
              ? where(
                  "clock_in",
                  ">=",
                  Timestamp.fromDate(new Date(queryParams?.startDate))
                )
              : undefined,
            queryParams?.endDate
              ? where(
                  "clock_in",
                  "<=",
                  Timestamp.fromDate(new Date(+queryParams?.endDate + 86400000))
                )
              : undefined
          );
    const querySnapshot = await getDocs(q);
    let data = querySnapshot.docs
      .map((doc) => doc.data())
      .sort((curr, prev) => prev.created_at - curr.created_at);

    if (queryParams?.user?.name) {
      data = data.filter(
        (presence) =>
          presence.user.id === queryParams.user.id ||
          presence.user.name === queryParams.user.name ||
          presence.user.email === queryParams.user.email
      );
      // console.log("qP", queryParams, data);
      return data;
    }

    if (queryParams?.team?.name && !queryParams?.noTeam) {
      data = data.filter(
        (presence) => presence.user.team === queryParams.team.name
      );
    }

    return data;
  } catch (e) {
    return false;
  }
};

export const getAllLogbookData = async (queryParams) => {
  try {
    const q =
      !queryParams?.startDate && !queryParams?.endDate
        ? query(collection(db, "logbooks"), orderBy("selected_date", "desc"))
        : query(
            collection(db, "logbooks"),
            queryParams?.startDate
              ? where(
                  "selected_date",
                  ">=",
                  Timestamp.fromDate(new Date(queryParams?.startDate))
                )
              : undefined,
            queryParams?.endDate
              ? where(
                  "selected_date",
                  "<=",
                  Timestamp.fromDate(new Date(+queryParams?.endDate + 86400000))
                )
              : undefined
          );

    const querySnapshot = await getDocs(q);
    let data = querySnapshot.docs
      .map((doc) => doc.data())
      .sort((curr, prev) => prev.selected_date - curr.selected_date);

    if (queryParams?.user?.name) {
      data = data.filter(
        (logbook) =>
          logbook.user.id === queryParams.user.id ||
          logbook.user.name === queryParams.user.name ||
          logbook.user.email === queryParams.user.email
      );

      // console.log("qL", queryParams, data);
      return data;
    }

    if (queryParams?.team?.name && !queryParams?.noTeam) {
      data = data.filter(
        (logbook) => logbook.user.team === queryParams.team.name
      );
    }

    return data;
  } catch (e) {
    return false;
  }
};

export const getAllTeamData = async () => {
  try {
    const q = query(collection(db, "teams"));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs
      .map((doc) => doc.data())
      .sort((curr, prev) => prev.updated_at - curr.updated_at);

    return data;
  } catch (e) {
    return false;
  }
};

export const getAllUserData = async () => {
  try {
    const q = query(collection(db, "users"), where("is_active", "==", true));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs
      .map((doc) => doc.data())
      .sort((curr, prev) => prev.updated_at - curr.updated_at);

    return data;
  } catch (e) {
    return false;
  }
};

export const getAllRoleData = async () => {
  try {
    const q = query(collection(db, "roles"));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs
      .map((doc) => doc.data())
      .sort((curr, prev) => prev.updated_at - curr.updated_at);

    return data;
  } catch (e) {
    return false;
  }
};

export const getAllWorkplaceData = async () => {
  try {
    const q = query(collection(db, "workplaces"));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs
      .map((doc) => doc.data())
      .sort((curr, prev) => prev.updated_at - curr.updated_at);

    return data;
  } catch (e) {
    return false;
  }
};

export const getPresenceInfo = async (userId) => {
  try {
    const q = query(
      collection(db, "presences"),
      where("user.id", "==", userId)
    );
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs
      .map((doc) => doc.data())
      .sort((curr, prev) => prev.clock_in - curr.clock_in);

    return data;
  } catch (e) {
    return false;
  }
};

export const getLogbookInfo = async (userId) => {
  try {
    const q = query(collection(db, "logbooks"), where("user.id", "==", userId));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs
      .map((doc) => doc.data())
      .sort((curr, prev) => prev.selected_date - curr.selected_date);

    return data;
  } catch (e) {
    return false;
  }
};

export const getUserInfo = async (userId) => {
  try {
    const q = query(collection(db, "users"), where("id", "==", userId));
    const querySnapshot = await getDocs(q);
    const [data] = querySnapshot.docs.map((doc) => doc.data());

    return data;
  } catch (e) {
    return false;
  }
};

export const getProjectByUserId = async (userId) => {
  try {
    const q = query(collection(db, "projects"), where("user_id", "==", userId));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());
    return data;
  } catch (e) {
    return false;
  }
};

export const getAllProgress = async (userId) => {
  try {
    const q = query(collection(db, "lov-progress"));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());

    return data;
  } catch (e) {
    return false;
  }
};

export const getAllTools = async (userId) => {
  try {
    const q = query(collection(db, "tools"));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());

    return data;
  } catch (e) {
    return false;
  }
};

export const getAllRequestById = async (userId) => {
  try {
    const q = query(
      collection(db, "requests"),
      where("requested_by", "==", userId)
    );
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());

    return data;
  } catch (e) {
    return false;
  }
};

export const getAllRequest = async () => {
  try {
    const q = query(collection(db, "requests"), orderBy("created_at", "desc"));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());

    return data;
  } catch (e) {
    return false;
  }
};

export const getAllHoliday = async () => {
  try {
    const q = query(collection(db, "holidays"));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());

    return data;
  } catch (e) {
    return false;
  }
};

export const getPlacesData = async (searchQuery) => {
  try {
    const result = await fetch(
      `https://geocode.maps.co/search?q=${searchQuery}`
    );
    return await result.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const resetUserPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success("Password reset email sent successfully");
    return true;
  } catch (e) {
    toast.error(e.message);
    return false;
  }
};

// Register to new service

export const registerNewService = async (payload) => {
  const res = await fetch(`${process.env.REACT_APP_BASEURL}/auth/register`, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(payload),
  });

  const data = await res.json();

  return data;
};

export const loginNewService = async (payload) => {
  const res = await fetch(`${process.env.REACT_APP_BASEURL}/auth/login`, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(payload),
  });

  const data = await res.json();

  sessionStorage.setItem("token", data.data.access_token);
  return data;
};

export const updateNewUserData = async (payload) => {
  let headers = { "Content-Type": "application/json" };
  const { userId } = jwtDecode(sessionStorage.getItem("token"));
  const auth = `Bearer ${sessionStorage.getItem("token")}`;

  headers["Authorization"] = auth;

  const res = await fetch(`${process.env.REACT_APP_BASEURL}/users/${userId}`, {
    headers,
    method: "PUT",
    body: JSON.stringify(payload),
  });

  return await res.json();
};
