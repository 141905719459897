import { Divider } from "@mui/material";
import SoftTypography from "components/UI/SoftTypography";
import React from "react";

const DefaultManagementPage = ({ title, table }) => {
  return (
    <>
      <SoftTypography variant="h5" fontWeight="bold" mt={2}>
        {title}
      </SoftTypography>
      <Divider />
      {table}
    </>
  );
};

export default DefaultManagementPage;
