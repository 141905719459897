import React, { useState } from "react";

import { Card, Chip, IconButton, Stack } from "@mui/material";
import {
  convertTimeFromDate,
  formatKey,
  getStyleAndText,
  responseHandler,
} from "utils";
import SoftTypography from "components/UI/SoftTypography";

import { Close } from "@mui/icons-material";
import { useUiStateStore } from "store/ui-state";

import DialogConfirm from "components/Dialogs/DialogConfirm";
import SoftButton from "components/UI/SoftButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRequest } from "new-services";

const RequestCard = ({ cardData, handleOpen }) => {
  const { text, styles } = getStyleAndText(
    cardData?.status[cardData?.status?.length - 1]?.title
  );

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deleteRequest, {
    onSuccess: (res) => {
      if (res.status_code === 200)
        queryClient.invalidateQueries({ queryKey: ["userRequest"] });

      responseHandler({ res });
    },
  });
  const [dialogConfirm, setDialogConfrim] = useState({ open: false });

  const { userLoggedIn } = useUiStateStore();

  const handleDelete = async () => {
    mutate(cardData?.fid);
  };

  const presenceDate =
    cardData?.type === "adjustment"
      ? cardData?.request_data?.from?.clock_in
      : cardData?.request_data?.clock_in;

  return (
    <>
      {" "}
      <DialogConfirm {...dialogConfirm} />
      <Card
        sx={({ boxShadows: { xl } }) => ({
          boxShadow: xl,
        })}
      >
        <Stack sx={{ p: 2 }}>
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <SoftTypography fontSize={20} fontWeight="bold" color="inherit">
              {formatKey(cardData?.title || "")}
            </SoftTypography>
            <Chip
              size="small"
              label={text}
              color={styles.color}
              sx={{ color: "#fff" }}
            />
            {process.env.REACT_APP_SUPER_USER ===
              userLoggedIn.userInfo.email && (
              <IconButton
                size="small"
                onClick={() => {
                  setDialogConfrim({
                    open: true,
                    title: "delete",
                    type: "request",
                    value: `(${cardData.fid})`,
                    loading: isLoading,
                    handleClose: () =>
                      setDialogConfrim({ ...dialogConfirm, open: false }),
                    action: handleDelete,
                  });
                }}
              >
                <Close />
              </IconButton>
            )}
          </Stack>
          <SoftTypography fontSize={14} color="inherit">
            Type : {formatKey(cardData?.type || "")}
          </SoftTypography>
          {presenceDate && (
            <SoftTypography fontSize={14} color="inherit">
              Presence Date :{" "}
              {convertTimeFromDate.toLocaleDateString(presenceDate)}
            </SoftTypography>
          )}
          <SoftTypography fontSize={14} color="inherit">
            Requested By : {formatKey(cardData?.requester_name || "")}
          </SoftTypography>
          <SoftTypography fontSize={14} color="inherit">
            Created Date:{" "}
            {convertTimeFromDate.toLocaleDateString(cardData?.created_at)}
          </SoftTypography>
          <Stack direction="row" justifyContent="flex-end">
            <SoftButton
              variant="outlined"
              color="error"
              sx={{ fontSize: 10, minHeight: 30, px: 1, py: 0 }}
              onClick={handleOpen}
            >
              Open Detail
            </SoftButton>
          </Stack>
        </Stack>
      </Card>
    </>
  );
};

export default RequestCard;
