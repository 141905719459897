import {
  Card,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import InputWithLabel from "components/Inputs/InputWithLabel";
import SoftBottomModal from "components/UI/SoftBottomModal";
import SoftButton from "components/UI/SoftButton";
import SoftModal from "components/UI/SoftModal";
import SoftTypography from "components/UI/SoftTypography";
import { updateRequest, updateUser } from "new-services";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUiStateStore } from "store/ui-state";
import { formatKey, responseHandler } from "utils";

const RequestProfileModal = ({
  cardData,
  type,
  chipStyle: { text, styles },
  open,
  handleClose,
}) => {
  const {
    setGlobalLoading,
    userLoggedIn,
    globalLoading,
    filteredAllRequest,
    allRequest,
    setAllRequest,
    setFilteredAllRequest,
  } = useUiStateStore();

  const [rejectedReason, setRejectedReason] = useState("");
  const matchesmd = useMediaQuery("(max-width:600px)");
  const ModalWrapper = matchesmd ? SoftBottomModal : SoftModal;

  const queryClient = useQueryClient();

  const handleUpdateResult = useCallback(
    (updateStatus, rejectedCustom) => {
      const reqIndex = filteredAllRequest.findIndex(
        (req) => req.fid === cardData.fid
      );
      const reqIndex2 = allRequest.findIndex((req) => req.fid === cardData.fid);
      const values = [...filteredAllRequest];
      const values2 = [...allRequest];
      values2[reqIndex2] = {
        ...values2[reqIndex2],
        status: updateStatus,
        checker_name: userLoggedIn.userInfo.name,
        rejected_reason: rejectedCustom || rejectedReason,
      };
      values[reqIndex] = {
        ...values[reqIndex],
        status: updateStatus,
        checker_name: userLoggedIn.userInfo.name,
        rejected_reason: rejectedCustom || rejectedReason,
      };
      setFilteredAllRequest(values);
      setAllRequest(values2);
    },
    [
      allRequest,
      cardData.fid,
      filteredAllRequest,
      rejectedReason,
      setAllRequest,
      setFilteredAllRequest,
      userLoggedIn.userInfo.name,
    ]
  );

  const { mutate: updateUserRequest, isLoading: loadUpdateReq } = useMutation(
    updateRequest,
    {
      onSuccess: (res) => {
        if (res.status_code === 200) {
          setRejectedReason("");
          handleClose();
        }
      },
    }
  );

  const { mutate: updateUserProfile, isLoading: loadUpdatePres } = useMutation(
    updateUser,
    {
      onSuccess: (res) => {
        if (res.status_code === 200) {
          queryClient.invalidateQueries({ queryKey: ["userLoggedIn"] });
          const updateStatus = [
            ...cardData.status,
            { title: "approved", date_time: new Date() },
          ];
          handleUpdateResult(updateStatus);
          updateUserRequest({
            id: cardData.fid,
            payload: {
              checker_name: userLoggedIn.userInfo.name,
              checked_by: userLoggedIn.userInfo.fid,
              status: [
                ...cardData.status,
                { title: "approved", date_time: new Date() },
              ],
            },
          });
        }
        responseHandler({
          res,
        });
      },
    }
  );

  const handleApprove = async () => {
    const now = new Date();
    const { team, workplace, company_role } = cardData.request_data.to;

    updateUserProfile({
      id: cardData.request_data.id,
      payload: {
        team_id: team.fid,
        workplace_id: workplace.fid,
        company_role_id: company_role.fid,
        updated_at: now,
      },
    });
  };

  const handleReject = () => {
    if (!rejectedReason) return toast.error("Please enter a reason");

    const updateStatus = [
      ...cardData.status,
      { title: "rejected", date_time: new Date() },
    ];

    handleUpdateResult(updateStatus);
    updateUserRequest({
      id: cardData.fid,
      payload: {
        checker_name: userLoggedIn.userInfo.name,
        checked_by: userLoggedIn.userInfo.fid,
        rejected_reason: rejectedReason,
        status: updateStatus,
      },
    });
  };

  useEffect(() => {
    setGlobalLoading(loadUpdatePres || loadUpdateReq);
  }, [loadUpdatePres, loadUpdateReq, setGlobalLoading]);

  return (
    <ModalWrapper title="Detail Request" open={open} handleClose={handleClose}>
      <Stack mt={-1} spacing={1} direction="row">
        <SoftTypography fontSize={14} fontWeight="bold">
          Status :
        </SoftTypography>
        <Chip
          size="small"
          label={text}
          color={styles.color}
          sx={{ color: "#fff" }}
        />
      </Stack>
      <Stack spacing={0.5}>
        <SoftTypography fontSize={14} fontWeight="bold">
          Type :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {formatKey(cardData.title)} {formatKey(cardData.type)}
          </SoftTypography>
        </SoftTypography>
        <SoftTypography fontSize={14} fontWeight="bold">
          Requester :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {cardData.requester_name}
          </SoftTypography>
        </SoftTypography>
        <SoftTypography fontSize={14} fontWeight="bold">
          Reason :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {cardData.request_data.reason}
          </SoftTypography>
        </SoftTypography>
        <SoftTypography fontSize={14} fontWeight="bold">
          Checked By :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {cardData?.checker_name}
          </SoftTypography>
        </SoftTypography>

        {cardData?.rejected_reason && (
          <SoftTypography fontSize={14} fontWeight="bold">
            Rejected Reason :{" "}
            <SoftTypography variant="span" fontWeight="medium">
              {cardData?.rejected_reason}
            </SoftTypography>
          </SoftTypography>
        )}
      </Stack>
      {cardData?.type === "adjustment" && (
        <Grid container spacing={1} width={!matchesmd ? 600 : "100%"}>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 2 }}>
              <SoftTypography fontSize={14} fontWeight="bold">
                From
              </SoftTypography>
              <Stack spacing={0.5}>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Team
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData.request_data.from.team.name}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Role
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData.request_data.from.company_role.name}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Workplace
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData.request_data.from.workplace.name}
                  </SoftTypography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 2 }}>
              <SoftTypography fontSize={14} fontWeight="bold">
                To
              </SoftTypography>
              <Stack spacing={0.5}>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Team
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData.request_data.to.team.name}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Role
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData.request_data.to.company_role.name}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Workplace
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData.request_data.to.workplace.name}
                  </SoftTypography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}

      {type === "administrator" &&
        cardData.status[cardData.status.length - 1]?.title?.includes(
          "process"
        ) && (
          <>
            <InputWithLabel
              sx={{ mt: 2 }}
              noLabel
              value={["", rejectedReason]}
              onChange={(e) => setRejectedReason(e.target.value)}
              placeholder="Rejected reason"
            />
            <Stack mt={1.5} direction="row" justifyContent="flex-end">
              <Stack direction="row" alignItems="center" spacing={1}>
                <SoftButton
                  onClick={handleReject}
                  variant="outlined"
                  color="error"
                  disabled={!rejectedReason}
                  endIcon={
                    globalLoading ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : null
                  }
                >
                  Reject
                </SoftButton>
                <SoftButton
                  onClick={handleApprove}
                  variant="gradient"
                  color="error"
                  endIcon={
                    globalLoading ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : null
                  }
                >
                  Approve
                </SoftButton>
              </Stack>
            </Stack>
          </>
        )}
    </ModalWrapper>
  );
};

export default RequestProfileModal;
