import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { formatKey } from "utils";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";

export default function SelectOption({
  options = [],
  onSelect = () => {},
  value: [key, val] = ["", ""],
  noLabel,
  selectProps,
  prefix,
  postfix,
  size = "medium",
  ...restProps
}) {
  return (
    <SoftBox width="100%" {...restProps}>
      {!noLabel && (
        <SoftBox mb={0.5} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {prefix} {key && formatKey(key)} {postfix}
          </SoftTypography>
        </SoftBox>
      )}
      <FormControl fullWidth>
        <Select value={val} name={key} onChange={onSelect} {...selectProps}>
          {options.map((opt, idx) => (
            <MenuItem key={idx} value={opt.value}>
              {opt.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SoftBox>
  );
}
