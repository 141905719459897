import { Delete, Edit, MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Stack } from "@mui/material";
import DialogConfirm from "components/Dialogs/DialogConfirm";
import SoftBox from "components/UI/SoftBox";
import SoftTypography from "components/UI/SoftTypography";
import { useState } from "react";
import HolidayModal from "components/Modals/HolidayModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { responseHandler } from "utils";
import { deleteHoliday } from "new-services";

const HolidayMenuAction = ({ rowData }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dialogConfirm, setDialogConfrim] = useState({ open: false });
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const queryClient = useQueryClient();

  const { isLoading, mutate: deleteAction } = useMutation(deleteHoliday, {
    onSuccess: (res) => {
      if (res.status_code === 200) {
        queryClient.invalidateQueries({ queryKey: ["holidays"] });
        setDialogConfrim({ ...dialogConfirm, open: false });
      }
      responseHandler({ res });
    },
  });

  return (
    <SoftBox>
      <HolidayModal
        title="Update Holiday Data"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        rowData={rowData}
      />
      <DialogConfirm {...dialogConfirm} loading={isLoading} />
      <IconButton fontSize={13} onClick={handleOpenMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setOpenModal(true);
            setOpenMenu(false);
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Edit />
            <SoftTypography sx={{ fontSize: 14 }}>
              Update Holiday
            </SoftTypography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDialogConfrim({
              open: true,
              title: "delete",
              type: "holiday",
              value: `(${rowData.name})`,

              handleClose: () =>
                setDialogConfrim({ ...dialogConfirm, open: false }),
              action: () => deleteAction(rowData.fid),
            });
            setOpenMenu(false);
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Delete />
            <SoftTypography sx={{ fontSize: 14 }}>
              Delete Holiday
            </SoftTypography>
          </Stack>
        </MenuItem>
      </Menu>
    </SoftBox>
  );
};

export default HolidayMenuAction;
