import { Grid, Skeleton, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useReducer } from "react";
import SoftTypography from "../SoftTypography";
import RequestCard from "components/Cards/RequestCard";
import FuzzyFilter from "../FuzzyFilter";
import SoftButton from "../SoftButton";

const initialState = {
  offset: 0,
  page: 1,
  limit: 21,
};

const scrollTop = () => {
  document.documentElement.scrollTop = 0;
  document.scrollingElement.scrollTop = 0;
};

const reducers = (state, newState) => ({ ...state, ...newState });

const SoftGridView = ({
  filteredData,
  filterProps,
  loading,
  handleOpen,
  customFilter,
}) => {
  const matchesMd = useMediaQuery("(max-width: 600px)");
  const [{ limit, offset, page }, dispatch] = useReducer(
    reducers,
    initialState
  );
  const handleNextPage = () => {
    scrollTop();
    dispatch({
      offset: limit + 1,
      page: page + 1,
      limit: limit + 1 + limit - offset,
    });
  };

  const handlePrevPage = () => {
    scrollTop();
    dispatch({
      offset: offset - (limit - offset) - 1,
      page: page - 1,
      limit: limit - limit - 1 + offset,
    });
  };

  useEffect(() => {
    dispatch(initialState);
  }, [filteredData.length]);

  const totalPageSize = useMemo(
    () => Math.max(Math.floor(filteredData.length / (limit - offset)) - 1, 1),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredData.length]
  );

  return (
    <>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
        mb={2}
      >
        {customFilter && customFilter}
        <FuzzyFilter data={filteredData} {...filterProps} />
      </Stack>
      <Grid container spacing={2}>
        {filteredData.length === 0 && !loading && (
          <Grid item xs={12}>
            <SoftTypography fontSize={16} textAlign="center" my={2}>
              No Request Available
            </SoftTypography>
          </Grid>
        )}
        {filteredData.length === 0 && loading
          ? Array(6)
              .fill()
              .map((a, idx) => (
                <Grid key={idx} item xs={12} md={6} lg={4}>
                  <Skeleton
                    variant="rounded"
                    sx={{
                      height: 150,
                    }}
                  />
                </Grid>
              ))
          : filteredData.slice(offset, limit).map((request) => (
              <Grid key={request.fid} item xs={12} md={6} lg={4}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      height: 150,
                    }}
                  />
                ) : (
                  <RequestCard
                    cardData={request}
                    handleOpen={() => handleOpen(request)}
                  />
                )}
              </Grid>
            ))}
      </Grid>
      <Stack
        direction="row"
        mt={2.5}
        spacing={1.5}
        alignItems="center"
        justifyContent={matchesMd ? "flex-start" : "flex-end"}
      >
        <SoftTypography fontSize={16}>
          Page: {page} of {totalPageSize}
        </SoftTypography>
        <SoftButton
          size="small"
          color="error"
          variant="gradient"
          disabled={page === 1}
          onClick={handlePrevPage}
        >
          Prev
        </SoftButton>
        <SoftButton
          size="small"
          color="error"
          variant="gradient"
          disabled={page === totalPageSize}
          onClick={handleNextPage}
        >
          Next
        </SoftButton>
      </Stack>
    </>
  );
};

export default SoftGridView;
