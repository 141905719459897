import HolidayMenuAction from "components/MenuActions/HolidayMenuAction";
import SoftTypography from "components/UI/SoftTypography";

export const columns = [
  {
    accessorKey: "value",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue() || "-"}</SoftTypography>
    ),
    header: "Name",
  },
  {
    accessorKey: "date",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue() || "-"}</SoftTypography>
    ),
    header: "Date",
  },
  {
    cell: (info) => {
      return <HolidayMenuAction rowData={info.row.original} />;
    },
    header: "Action",
  },
];
