import { Link } from "@mui/material";
import SoftTypography from "components/UI/SoftTypography";

export const columns = [
  {
    accessorKey: "Nama Dokumen",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue() || "-"}</SoftTypography>
    ),
    header: "Nama",
  },
  {
    accessorKey: "Jenis",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue() || "-"}</SoftTypography>
    ),
    header: "Jenis",
  },
  {
    accessorKey: "Link",
    cell: (info) => (
      <Link fontSize={13} href={info.getValue()} target="_blank">
        {info.getValue() || "-"}
      </Link>
    ),
    header: "Link",
    width: "95%",
  },
];
