import {
  Card,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import InputWithLabel from "components/Inputs/InputWithLabel";
import SoftBottomModal from "components/UI/SoftBottomModal";
import SoftButton from "components/UI/SoftButton";
import SoftModal from "components/UI/SoftModal";
import SoftTypography from "components/UI/SoftTypography";
import { addPresence, updatePresence, updateRequest } from "new-services";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUiStateStore } from "store/ui-state";
import {
  convertTimeFromDate,
  convertToHoursMinutes,
  formatKey,
  getUUID,
  isLtEightHours,
  responseHandler,
} from "utils";

const RequestCardModal = ({
  cardData,
  type,
  chipStyle: { text, styles },
  open,
  handleClose,
}) => {
  const {
    setGlobalLoading,
    userLoggedIn,
    globalLoading,
    filteredAllRequest,
    allRequest,
    setAllRequest,
    setFilteredAllRequest,
  } = useUiStateStore();

  const [rejectedReason, setRejectedReason] = useState("");
  const matchesmd = useMediaQuery("(max-width:600px)");
  const ModalWrapper = matchesmd ? SoftBottomModal : SoftModal;

  const queryClient = useQueryClient();

  const handleUpdateResult = useCallback(
    (updateStatus, rejectedCustom) => {
      const reqIndex = filteredAllRequest.findIndex(
        (req) => req.fid === cardData.fid
      );
      const reqIndex2 = allRequest.findIndex((req) => req.fid === cardData.fid);
      const values = [...filteredAllRequest];
      const values2 = [...allRequest];
      values2[reqIndex2] = {
        ...values2[reqIndex2],
        status: updateStatus,
        checker_name: userLoggedIn.userInfo.name,
        rejected_reason: rejectedCustom || rejectedReason,
      };
      values[reqIndex] = {
        ...values[reqIndex],
        status: updateStatus,
        checker_name: userLoggedIn.userInfo.name,
        rejected_reason: rejectedCustom || rejectedReason,
      };
      setFilteredAllRequest(values);
      setAllRequest(values2);
    },
    [
      allRequest,
      cardData.fid,
      filteredAllRequest,
      rejectedReason,
      setAllRequest,
      setFilteredAllRequest,
      userLoggedIn.userInfo.name,
    ]
  );

  const { mutate: updateUserRequest, isLoading: loadUpdateReq } = useMutation(
    updateRequest,
    {
      onSuccess: (res) => {
        if (res.status_code === 200) {
          setRejectedReason("");
          handleClose();
        }
      },
    }
  );

  const { mutate: updateUserPresence, isLoading: loadUpdatePres } = useMutation(
    updatePresence,
    {
      onSuccess: (res) => {
        if (res.status_code === 200) {
          queryClient.invalidateQueries({ queryKey: ["presences"] });
          const updateStatus = [
            ...cardData.status,
            { title: "approved", date_time: new Date() },
          ];
          handleUpdateResult(updateStatus);
          updateUserRequest({
            id: cardData.fid,
            payload: {
              checker_name: userLoggedIn.userInfo.name,
              checked_by: userLoggedIn.userInfo.fid,
              status: [
                ...cardData.status,
                { title: "approved", date_time: new Date() },
              ],
            },
          });
        }
        responseHandler({
          res,
        });
      },
    }
  );

  const { mutate: addUserPresence, isLoading: loadAddPres } = useMutation(
    addPresence,
    {
      onSuccess: (res) => {
        if (res.status_code === 201) {
          queryClient.invalidateQueries({ queryKey: ["presences"] });
          const updateStatus = [
            ...cardData.status,
            { title: "approved", date_time: new Date() },
          ];
          handleUpdateResult(updateStatus);
          updateUserRequest({
            id: cardData.fid,
            payload: {
              checker_name: userLoggedIn.userInfo.name,
              checked_by: userLoggedIn.userInfo.fid,
              status: updateStatus,
            },
          });
        }
        if (res.status_code === 409) {
          const updateStatus = [
            ...cardData.status,
            { title: "rejected", date_time: new Date() },
          ];
          handleUpdateResult(
            updateStatus,
            "Silahkan lakukan perubahan data melalui adjustment"
          );
          updateUserRequest({
            id: cardData.fid,
            payload: {
              checker_name: userLoggedIn.userInfo.name,
              checked_by: userLoggedIn.userInfo.fid,
              rejected_reason:
                "Silahkan lakukan perubahan data melalui adjustment",
              status: updateStatus,
            },
          });
        }
        responseHandler({
          res,
        });
      },
    }
  );

  const handleApprove = async () => {
    const now = new Date();

    cardData.type === "create"
      ? addUserPresence({
          fid: getUUID(),
          work_mode: cardData.request_data.work_mode,
          user: cardData.request_data.user,
          clock_in: cardData.request_data.clock_in,
          clock_out: cardData.request_data.clock_out,
          created_at: cardData.request_data.created_at,
          updated_at: now,
          location: cardData.request_data.location,
          user_id: cardData.request_data.user_id,
        })
      : updateUserPresence({
          id: cardData.request_data.id,
          payload: {
            ...cardData.request_data.to,
            updated_at: now,
          },
        });
  };

  const handleReject = () => {
    if (!rejectedReason) return toast.error("Please enter a reason");

    const updateStatus = [
      ...cardData.status,
      { title: "rejected", date_time: new Date() },
    ];

    handleUpdateResult(updateStatus);
    updateUserRequest({
      id: cardData.fid,
      payload: {
        checker_name: userLoggedIn.userInfo.name,
        checked_by: userLoggedIn.userInfo.fid,
        rejected_reason: rejectedReason,
        status: updateStatus,
      },
    });
  };

  const startDate =
    cardData?.type === "adjustment"
      ? cardData?.request_data?.to?.clock_in
      : cardData?.request_data?.clock_in;

  const endDate =
    cardData?.type === "adjustment"
      ? cardData?.request_data?.to?.clock_out
      : cardData?.request_data?.clock_out;

  useEffect(() => {
    setGlobalLoading(loadAddPres || loadUpdatePres || loadUpdateReq);
  }, [loadAddPres, loadUpdatePres, loadUpdateReq, setGlobalLoading]);

  return (
    <ModalWrapper title="Detail Request" open={open} handleClose={handleClose}>
      <Stack mt={-1} spacing={1} direction="row">
        <SoftTypography fontSize={14} fontWeight="bold">
          Status :
        </SoftTypography>
        <Chip
          size="small"
          label={text}
          color={styles.color}
          sx={{ color: "#fff" }}
        />
      </Stack>
      <Stack spacing={0.5}>
        <SoftTypography fontSize={14} fontWeight="bold">
          Type :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {formatKey(cardData.title)} {formatKey(cardData.type)}
          </SoftTypography>
        </SoftTypography>
        <SoftTypography fontSize={14} fontWeight="bold">
          Requester :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {cardData.requester_name}
          </SoftTypography>
        </SoftTypography>
        <SoftTypography fontSize={14} fontWeight="bold">
          Reason :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {cardData.request_data.reason}
          </SoftTypography>
        </SoftTypography>
        <SoftTypography fontSize={14} fontWeight="bold">
          Checked By :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {cardData?.checker_name}
          </SoftTypography>
        </SoftTypography>

        {cardData?.rejected_reason && (
          <SoftTypography fontSize={14} fontWeight="bold">
            Rejected Reason :{" "}
            <SoftTypography variant="span" fontWeight="medium">
              {cardData?.rejected_reason}
            </SoftTypography>
          </SoftTypography>
        )}
      </Stack>
      {cardData?.type === "adjustment" && (
        <Grid container spacing={1} width={!matchesmd ? 600 : "100%"}>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 2 }}>
              <SoftTypography fontSize={14} fontWeight="bold">
                From
              </SoftTypography>
              <Stack spacing={0.5}>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Work Mode
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData?.request_data?.from?.work_mode}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Clock In
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {convertTimeFromDate.toLocaleDateString(
                      cardData?.request_data?.from?.clock_in
                    )}{" "}
                    {convertTimeFromDate.toLocaleTimeString(
                      cardData?.request_data?.from?.clock_in
                    )}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Clock Out
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData?.request_data?.from?.clock_out &&
                      convertTimeFromDate.toLocaleDateString(endDate)}{" "}
                    {cardData?.request_data?.from?.clock_out &&
                      convertTimeFromDate.toLocaleTimeString(
                        cardData?.request_data?.from?.clock_out
                      )}
                  </SoftTypography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 2 }}>
              <SoftTypography fontSize={13} fontWeight="bold">
                To
              </SoftTypography>
              <Stack spacing={0.5} width="100%">
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Work Mode
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData?.request_data?.to.work_mode}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Clock In
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData?.request_data?.to?.clock_in &&
                      convertTimeFromDate.toLocaleDateString(
                        cardData?.request_data?.to?.clock_in
                      )}{" "}
                    {cardData?.request_data?.to?.clock_in &&
                      convertTimeFromDate.toLocaleTimeString(
                        cardData?.request_data?.to?.clock_in
                      )}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Clock Out
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {cardData?.request_data?.to?.clock_out &&
                      convertTimeFromDate.toLocaleDateString(
                        cardData?.request_data?.to?.clock_out
                      )}{" "}
                    {cardData?.request_data?.to?.clock_out &&
                      convertTimeFromDate.toLocaleTimeString(
                        cardData?.request_data?.to?.clock_out
                      )}
                  </SoftTypography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}

      {cardData?.type === "create" && (
        <Card sx={{ p: 2 }}>
          <SoftTypography fontSize={14} fontWeight="bold">
            Presence Data
          </SoftTypography>
          <Stack spacing={0.5}>
            <Stack direction="row">
              <SoftTypography fontSize={13} width="30%">
                Work Mode
              </SoftTypography>
              <SoftTypography fontSize={13}>
                {cardData?.request_data?.work_mode}
              </SoftTypography>
            </Stack>
            <Stack direction="row">
              <SoftTypography fontSize={13} width="30%">
                Clock In
              </SoftTypography>
              <SoftTypography fontSize={13}>
                {convertTimeFromDate.toLocaleDateString(startDate)}{" "}
                {convertTimeFromDate.toLocaleTimeString(startDate)}
              </SoftTypography>
            </Stack>
            <Stack direction="row">
              <SoftTypography fontSize={13} width="30%">
                Clock Out
              </SoftTypography>
              <SoftTypography fontSize={13}>
                {convertTimeFromDate.toLocaleDateString(endDate)}{" "}
                {convertTimeFromDate.toLocaleTimeString(endDate)}
              </SoftTypography>
            </Stack>
          </Stack>
        </Card>
      )}
      {type === "administrator" && (
        <SoftTypography
          sx={{
            mt: 2,
            fontSize: 13,
            textAlign: "right",
          }}
          color={
            isLtEightHours(new Date(startDate) / 1000, new Date(endDate) / 1000)
              ? "error"
              : undefined
          }
        >
          {" "}
          Work hours estimation :{" "}
          {convertToHoursMinutes(
            (new Date(endDate) - new Date(startDate)) / 1000
          )}
        </SoftTypography>
      )}

      {type === "administrator" &&
        cardData.status[cardData.status.length - 1]?.title?.includes(
          "process"
        ) && (
          <>
            <InputWithLabel
              sx={{ mt: 2 }}
              noLabel
              value={["", rejectedReason]}
              onChange={(e) => setRejectedReason(e.target.value)}
              placeholder="Rejected reason"
            />
            <Stack mt={1.5} direction="row" justifyContent="flex-end">
              <Stack direction="row" alignItems="center" spacing={1}>
                <SoftButton
                  onClick={handleReject}
                  variant="outlined"
                  color="error"
                  disabled={!rejectedReason}
                  endIcon={
                    globalLoading ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : null
                  }
                >
                  Reject
                </SoftButton>
                <SoftButton
                  onClick={handleApprove}
                  variant="gradient"
                  color="error"
                  endIcon={
                    globalLoading ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : null
                  }
                  disabled={
                    isLtEightHours(
                      new Date(startDate) / 1000,
                      new Date(endDate) / 1000
                    ) && !cardData?.request_data?.work_mode?.includes("Leave")
                  }
                >
                  Approve
                </SoftButton>
              </Stack>
            </Stack>
          </>
        )}
    </ModalWrapper>
  );
};

export default RequestCardModal;
