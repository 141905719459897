/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard React components
import SoftBox from "components/UI/SoftBox";
import SoftTypography from "components/UI/SoftTypography";
import SoftAvatar from "components/UI/SoftAvatar";

// Soft UI Dashboard React icons
import Document from "components/Icons/Document";

// Images
import defaultProfile from "assets/images/default-profile.png";
import { useUiStateStore } from "store/ui-state";
import { formatKey } from "utils";
import Office from "components/Icons/Office";
import useHeader from "hooks/use-header";

function RequestHeader() {
  const { tabsOrientation } = useHeader();
  const { currentRequestTab, setCurrentRequestTab, userLoggedIn } =
    useUiStateStore();
  const handleSetTabValue = (event, newValue) => setCurrentRequestTab(newValue);

  return (
    <SoftBox position="relative">
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item>
            <SoftAvatar
              src={userLoggedIn?.userInfo?.imageUrl || defaultProfile}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {userLoggedIn?.userInfo?.name}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {userLoggedIn?.userInfo?.company_role?.name}{" "}
                {userLoggedIn?.userInfo?.system_role === "administrator" &&
                  " | " + formatKey(userLoggedIn?.userInfo?.system_role)}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={8} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={currentRequestTab}
                onChange={handleSetTabValue}
                sx={{ background: "transparent" }}
              >
                <Tab label="Presence" icon={<Document />} index={0} />
                <Tab label="Profile" icon={<Office />} index={1} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default RequestHeader;
