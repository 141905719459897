/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

import DefaultManagementPage from "components/UI/DefaultManagementPage";
import DocumentsHeader from "components/Headers/DocumentsHeader";
import ISO9001Table from "components/Tables/ISO9001Table";

function Documents() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DocumentsHeader />
      <DefaultManagementPage table={<ISO9001Table />} />
    </DashboardLayout>
  );
}

export default Documents;
