/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/UI/SoftBox";
import SoftTypography from "components/UI/SoftTypography";
import SoftInput from "components/UI/SoftInput";
import SoftButton from "components/UI/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import curved6 from "assets/images/curved-images/curved14.jpg";

import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "new-services";
import { validatePassword } from "utils";

function ResetPassword() {
  const navigate = useNavigate();

  const { token } = useParams();

  const { isLoading, mutate } = useMutation(resetPassword, {
    onSuccess: (res) => {
      if (res.status_code === 200) {
        navigate("/authentication/sign-in");
        toast.success("Password reset successfully");
      } else if (res.status_code === 401) {
        toast.error(
          `${res.message}, you will be redirected to login. please contact administrator to request new link.`
        );
        setTimeout(() => {
          navigate("/authentication/sign-in");
        }, 3000);
      } else {
        toast.error(res.message);
      }
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const handleReset = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (!formData.get("password") || !formData.get("validatePassword")) {
      return toast.error("Please enter all required fields");
    }

    if (formData.get("password") !== formData.get("validatePassword")) {
      return toast.error("Password not match");
    }

    if (!validatePassword(formData.get("password"))) {
      return;
    }

    mutate({
      token,
      payload: {
        password: formData.get("password"),
      },
    });
  };

  return (
    <BasicLayout
      title="Reset Password!"
      description="Update your password and keep it secret."
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium" color="error">
            Update Password
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" onSubmit={handleReset}>
            <SoftBox mb={2}>
              <SoftInput
                type="password"
                placeholder="Password"
                name="password"
              />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput
                type="password"
                placeholder="Re Type Password"
                name="validatePassword"
              />
            </SoftBox>

            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                endIcon={
                  isLoading ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : null
                }
                disabled={isLoading}
              >
                submit
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
