import { Card, Divider, useMediaQuery } from "@mui/material";
import SoftDataTable from "components/UI/SoftDataTable";
import SoftBox from "components/UI/SoftBox";
import SoftTypography from "components/UI/SoftTypography";
import { useState } from "react";
import { useUiStateStore } from "store/ui-state";
import { columns } from "./data/holidayDataTable";
import SoftButton from "components/UI/SoftButton";
import { exportExcel } from "utils";
import { AddCircle } from "@mui/icons-material";
import HolidayModal from "components/Modals/HolidayModal";
import { useQuery } from "@tanstack/react-query";
import { getHolidays } from "new-services";

const HolidayTable = () => {
  const { setAllHolidayData, allHolidayData, setHolidaysMap } =
    useUiStateStore();
  const [openModal, setOpenModal] = useState(false);
  const matchesMd = useMediaQuery("(max-width:600px)");

  const { isLoading } = useQuery(["holidays"], getHolidays, {
    onSuccess: (res) => {
      if (res.status_code === 200) {
        setAllHolidayData(res.data);
        const holidaysMap = new Map(res.data.map((h) => [h.date, h]));
        setHolidaysMap(holidaysMap);
      }
    },
  });

  return (
    <Card>
      <HolidayModal
        title="Add New Holiday"
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={4}
      >
        <SoftTypography variant="h5">Holidays Data</SoftTypography>
        <SoftButton
          variant="gradient"
          onClick={() => setOpenModal(true)}
          size="small"
          color="error"
          endIcon={<AddCircle />}
        >
          Add {matchesMd ? "" : "Holiday"}
        </SoftButton>
      </SoftBox>
      <Divider />

      <SoftDataTable
        table={{ columns, rows: allHolidayData || [] }}
        isLoading={isLoading}
        withExport
        exportFn={() =>
          exportExcel(
            allHolidayData.map(({ name, date }) => ({ name, date })),
            "holiday-data"
          )
        }
      />
    </Card>
  );
};

export default HolidayTable;
