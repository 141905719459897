import RequestHeader from "components/Headers/RequestHeader";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import React from "react";
import Presence from "./pages/Presence";
import { useUiStateStore } from "store/ui-state";
import TabPanel from "components/UI/TabPanel";
import withAdminAuth from "layouts/hoc-admin-page";
import Profile from "./pages/Profile";

const requestPages = [{ component: <Presence /> }, { component: <Profile /> }];
const RequestPage = () => {
  const { currentRequestTab } = useUiStateStore();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <RequestHeader />
      {requestPages.map(({ component }, index) => (
        <TabPanel index={index} value={currentRequestTab} key={index}>
          {component}
        </TabPanel>
      ))}
    </DashboardLayout>
  );
};

export default withAdminAuth(RequestPage);
