import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useUiStateStore } from "store/ui-state";
import FallbackUI from "./fallback-ui";

const withAdminAuth = (Component) => {
  return (props) => {
    const {
      userLoggedIn: { userInfo },
    } = useUiStateStore();
    const navigate = useNavigate();

    useEffect(() => {
      if (userInfo?.system_role?.toLowerCase() !== "administrator") {
        toast.error("Forbidden Access!");
        navigate("/profile");
      }
    }, [navigate, userInfo?.system_role]);

    if (userInfo?.system_role?.toLowerCase() !== "administrator")
      return <FallbackUI />;

    return <Component {...props} />;
  };
};

export default withAdminAuth;
