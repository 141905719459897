// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/UI/SoftBox";
import SoftTypography from "components/UI/SoftTypography";
import SoftInput from "components/UI/SoftInput";
import SoftButton from "components/UI/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import curved6 from "assets/images/curved-images/curved14.jpg";

import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "config/firebase";
import {
  getUserInfo,
  loginNewService,
  registerNewService,
  updateNewUserData,
} from "services";
import { useState } from "react";

const migrateUserData = async (userData) => {
  const { email, password, name, ...restData } = userData;
  const registerUser = await registerNewService({
    email,
    password,
    name,
    type: "update",
  });

  if (registerUser?.data?.is_synced) {
    console.log("Already synced");
    return registerUser;
  }

  const loginUser = await loginNewService({ email, password });
  if (loginUser?.status_code === 200) {
    if (
      registerUser?.status_code === 201 ||
      registerUser?.message === "Email already in used"
    ) {
      const payload = {
        fid: restData.id,
        name,
        email,
        address: restData.address,
        profile_desc: restData.profile_desc,
        phone: restData.phone,
        image_url: restData.imageUrl,
        system_role: restData.system_role,
        team_id: restData.team.id,
        workplace_id: restData.workplace.id,
        company_role_id: restData.company_role.id,
        is_active: restData.is_active,
        is_verified: restData.is_verified,
        is_synced: true,
      };
      const updateUser = await updateNewUserData(payload);
      if (updateUser?.status_code === 200) console.log("user updated");
    }
  }

  return loginUser;
};

function MigrateAccount() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleMigrate = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    if (!formData.get("email") || !formData.get("password")) {
      setLoading(false);
      return toast.error("Invalid Email or Password");
    }
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.get("email"),
        formData.get("password")
      );
      if (userCredential) {
        const result = await getUserInfo(userCredential.user.uid);
        if (result) {
          const res = await migrateUserData({
            ...result,
            password: formData.get("password"),
          });
          if (res.status_code === 200) {
            navigate("/authentication/sign-in");
            toast.success("Your account migrated successfully");
            sessionStorage.clear();
          }
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
    setLoading(false);
  };
  return (
    <BasicLayout
      title="Migrate User Account!"
      description="Please use your registered account."
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium" color="error">
            Migrate Account{" "}
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" onSubmit={handleMigrate}>
            <SoftBox mb={2}>
              <SoftInput type="email" placeholder="Email" name="email" />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput
                type="password"
                placeholder="Password"
                name="password"
              />
            </SoftBox>

            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                endIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : null
                }
                disabled={loading}
              >
                submit
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default MigrateAccount;
